import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IconChevronLeft } from '../components/Icons'
import { slugify } from '../helpers/StringHelpers'

export function TableOfContentsHTML({ sections }) {
  const [toc, setToc] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    generateTableOfContents()
  }, [sections])

  const generateTableOfContents = () => {
    if (!sections || !sections.length) return
    const newToc = sections.reduce((tocList, section) => {
      if (section.heading) {
        const decodeHeading = section.heading.replace(/&amp;/g, '&').replace(/&nbsp;/g, ' ')

        tocList.push({
          id: slugify(section.heading),
          text: decodeHeading,
        })
      }
      return tocList
    }, [])

    setToc(newToc)
  }

  return (
    <div
      className={
        'w-full max-w-[800px] px-5 py-2 shadow-lg h-10 lg:h-12 overflow-hidden border duration-500 ease-in-out order-2 relative'
      }
      style={open ? { height: `${toc.length * 3}rem` } : {}}
    >
      <div
        className='flex justify-between items-center cursor-pointer before:content-[" "] before:absolute before:left-0 before:top-0 before:w-2 before:h-full before:bg-red-700 '
        onClick={() => setOpen(!open)}
      >
        <h4 className="text-xl lg:text-2xl text-gray-800 font-bold uppercase ">
          Table of Contents
        </h4>
        <IconChevronLeft
          className={`${open ? 'rotate-90' : '-rotate-90'} transition-all duration-500`}
        />
      </div>
      <nav className="flex flex-col gap-3 mt-2" aria-label="Table of Contents">
        {toc.map(item => (
          <a
            href={`#${item.id}`}
            key={item.id}
            className={'text-gray-800 hover:text-red-700 text-lg space-y-2 truncate'}
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        ))}
      </nav>
    </div>
  )
}

TableOfContentsHTML.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
    })
  ),
}
