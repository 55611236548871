import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BlogRelatedItems from '../components/BlogRelatedItems'
import BlogsSlider from '../components/sliders/BlogsSlider'
import { TableOfContentsHTML } from '../components/TableOfContents'
import Section from '../components/cms/Section'
import PropTypes from 'prop-types'
import { IconChevronLeft, IconChevronRight } from '../components/Icons'
import { convertDateTimeString } from '../utils/previewDateFormat'

export const BlogPostSectionRenderer = ({
  page,
  next,
  previous,
  recommended,
  products,
  breadcrumbs,
  firstSection,
  otherSections,
}) => {
  return (
    <Layout className={'mx-auto'} breadcrumbs={breadcrumbs} canonical={page.url} title={page.title}>
      <Seo title={page.title} canonical={page.url} article={page} seo={page.seo} />
      <h1 className=" text-black-700 my-5 mx-auto w-10/12 lg:max-w-[800px] max-w-[1200px] xl:max-w-[1200px] text-center text-lg font-bold lg:font-normal lg:text-3xl uppercase">
        {page.title}
      </h1>
      <div className="relative mx-auto flex w-10/12 lg:max-w-[800px] justify-between flex-col lg:flex-wrap  xl:w-full xl:max-w-[1200px] xl:flex-row">
        <BlogRelatedItems products={products.nodes} />

        <section className="order-1 max-w-[800px] leading-loose pb-4">
          <div className=" border-grey-700 border-b mb-4">
            ARTICLE DATE {convertDateTimeString(page.publishedAt)}
          </div>
          {/* TODO: Place Hero Image here */}
          {firstSection && (
            <Section
              section={{
                ...firstSection,
                heading: firstSection.heading === page.title ? null : firstSection.heading,
              }}
            />
          )}
        </section>
        {page.table_of_contents ? <TableOfContentsHTML sections={page.sections} /> : null}
        {otherSections &&
          otherSections.map(section => {
            return (
              <Section
                key={section.id}
                section={section}
                className="order-2 max-w-[800px] leading-loose cms"
              />
            )
          })}
      </div>
      <div className="my-10 mx-auto flex w-10/12 max-w-[1200px] flex-row gap-4 ">
        {previous ? (
          <Link to={previous.url} className="w-1/2 group">
            <p className="text-sm group-hover:text-red-500 text-slate-500  flex flex-row items-center gap-2">
              <div className="w-8 h-8  text-slate-500 rounded-full border-2 border-gray-500 group-hover:border-red-500 group-hover:bg-red-500 group-hover:text-white flex justify-center items-center duration-150">
                <IconChevronLeft className="stroke-1 hover:stroke-white" />
              </div>
              PREVIOUS ARTICLE
            </p>
            <p className="">{previous.title}</p>
          </Link>
        ) : (
          <div className="w-1/2" />
        )}
        {next ? (
          <Link to={next.url} className="w-1/2 group text-right">
            <div className="w-full flex flex-row justify-end">
              <p className="text-sm group-hover:text-red-500 text-slate-500  flex flex-row items-center px-2">
                NEXT ARTICLE
              </p>
              <div className="w-8 h-8 hover:text-red-500 text-slate-500 rounded-full border-2 border-gray-500 group-hover:border-red-500 group-hover:bg-red-500 group-hover:text-white flex justify-center items-center duration-150">
                <IconChevronRight className="stroke-1 hover:stroke-white" />
              </div>
            </div>

            <p className="w-full">{next.title}</p>
          </Link>
        ) : (
          <div className="w-1/2" />
        )}
      </div>
      <section className="bg-gray-100 py-10">
        <BlogsSlider
          blogs={recommended.nodes}
          className="w-10/12 max-w-[1200px] mx-auto "
          h2Title="RECOMMENDED ARTICLES"
        />
        {/* {recommended.nodes.map(post => (
              
              // <Link
              //   to={post.url}
              //   key={post.id}
              //   className=" w-1/3 max-w-sm rounded-lg bg-white py-4 px-4 shadow-lg duration-200 hover:scale-105 "
              // >
              //   <div className="aspect-square mb-5">
              //     {post.heroImage ? (
              //       <GatsbyImage
              //         alt={post.title}
              //         image={getImage(post.heroImage)}
              //         className="object-fill w-full h-full"
              //       />
              //     ) : (
              //       <StaticImage
              //         src="../images/placeholders/blog-post-thumb-image.jpeg"
              //         alt="The History and Evolution of the Rolex Submariner Date"
              //         className="object-fill w-full h-full"
              //       />
              //     )}
              //   </div>

              //   <div className="h-8">
              //     {post.tag
              //       ?.filter(tag => tag.category)
              //       .map(tag => {
              //         return (
              //           <span
              //             key={tag.category}
              //             className="mr-1 inline-block rounded bg-red-600 py-1 px-2 text-xs font-semibold uppercase text-white last:mr-0 hover:bg-red-200"
              //           >
              //             <span className="text-white">{tag.category}</span>
              //           </span>
              //         )
              //       })}
              //   </div>
              //   <h3 className="line-clamp-2">{post.title}</h3>
              //   <p className="text-gray-400">{post.publishedAt}</p>
              // </Link>
            ))} */}
      </section>
    </Layout>
  )
}

BlogPostSectionRenderer.propTypes = {
  page: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        content: PropTypes.string,
        videos: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
        strapi_component: PropTypes.string,
      })
    ),
    title: PropTypes.string,
    url: PropTypes.string,
    publishedAt: PropTypes.string,
    table_of_contents: PropTypes.bool,
    seo: PropTypes.shape({
      metaRobots: PropTypes.string,
      metaViewport: PropTypes.string,
      keywords: PropTypes.string,
      canonicalURL: PropTypes.string,
      structuredData: PropTypes.shape({
        internal: PropTypes.shape({
          content: PropTypes.string,
        }),
      }),
      metaTitle: PropTypes.string.isRequired,
      metaDescription: PropTypes.string.isRequired,
      metaSocial: PropTypes.arrayOf(
        PropTypes.shape({
          socialNetwork: PropTypes.oneOf(['Facebook', 'Twitter']),
          title: PropTypes.string,
          description: PropTypes.string,
          image: PropTypes.shape({
            twitterSocialImage: PropTypes.string,
            facebookSocialImage: PropTypes.string,
          }),
        })
      ),
    }),
    heroImage: PropTypes.object,
  }),
  next: PropTypes.object,
  previous: PropTypes.object,
  recommended: PropTypes.object,
  products: PropTypes.object,
  breadcrumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  ]),
  firstSection: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    strapi_component: PropTypes.string,
  }),
  otherSections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      content: PropTypes.string,
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      strapi_component: PropTypes.string,
    })
  ),
}
